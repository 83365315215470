export class TabsWithHashComponent {
  constructor(el) {
    this.root = el.element
    this.window = window
    this.tabsNavigation = this.root.querySelector('[data-hash-tabs-navigation]')
    this.tabsContent = this.root.querySelector('[data-hash-tabs-content]')
    this.addAttributesForTurbolinks()
    this._bindEvents()
    this._changeActiveTab(location.hash)
  }

  _bindEvents() {
    this.window.addEventListener('hashchange', () => this._changeActiveTab(location.hash))
  }

  addAttributesForTurbolinks() {
    Array.from(this.tabsNavigation.querySelectorAll('[data-hash-tabs-navigation-id]')).forEach(function (el) {
      el.setAttribute('data-turbolinks', 'false')
      el.setAttribute('data-turbo', 'false')
    })
  }

  _changeActiveTab(hash) {
    if (hash) {
      const navItem = this.tabsNavigation.querySelector(`[data-hash-tabs-navigation-id="${hash}"]`)
      if (navItem) {
        Array.from(this.tabsNavigation.querySelectorAll('[data-hash-tabs-navigation-id]')).forEach(function (el) {
          el.classList.remove('active')
        })
        Array.from(this.root.querySelectorAll('[data-hash-tabs-content-id]')).forEach(function (el) {
          el.classList.add('hide')
        })
        navItem.classList.add('active')
        Array.from(this.root.querySelectorAll(`[data-hash-tabs-content-id="${hash}"]`)).forEach(function (el) {
          el.classList.remove('hide')
        })
      }
    }
  }
}
